import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { getAddOnBenefit, getUrlParams } from '../../helpers/location';
import { displayCurrency } from '../../helpers/currency';
import { subtotal } from '../../helpers/utility';
import { createLoadingSelector } from '../../reducers/api';
import { createSelectedMenusSelector } from '../../reducers/menus';
import { addMenu, removeMenu } from '../../actions/menus';
import { postOrder, postOrderSummary } from '../../actions/orders';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout';
import CheckoutForm from '../../components/CheckoutForm';
import CheckoutItems from '../../components/CheckoutItems';
import CheckoutSummary from '../../components/CheckoutSummary';
import Payment from '../../components/Payment';
import Promotion from '../../components/Promotion';
import {
  setAddOnBenefit,
} from '../../actions/partners';
import {
  checkMembershipCardBenefit,
  setCanUseBenefit,
} from '../../actions/membership';

export default ({ location }) => {
  const dispatch = useDispatch();
  const [summary, setSummary] = React.useState();
  const orderInfo = useSelector(state => state.orderInfo);
  const { chatroom, card, add_on_benefit: addOnBenefit } = getUrlParams(location.search);
  const loading = useSelector(createLoadingSelector(['order/summary/post', 'order/post']));
  const currentSelected = useSelector(createSelectedMenusSelector);
  const { user } = useSelector(state => state.session);
  const { payment, collaboration, address, promotionId, deliveryMethod } = orderInfo;
  const subTotalPrice = subtotal(currentSelected.items);
  const { canUseBenefit } = useSelector(state => state.memberships);

  React.useEffect(() => {
    window.logEvent('begin_checkout', { currency: 'THB', value: subTotalPrice });
  }, []);

  React.useEffect(() => {
    if (card && addOnBenefit)
      checkCanUseAddOnBenefit()
        .then(canUse => {
          if (canUse === undefined) return Promise.resolve(undefined);
          if (canUse) return Promise.resolve(false);
          return showCannotUseBenefitPage();
        })
        .then(showCannotUseBenefitPage => {
          const waitingForResponse = showCannotUseBenefitPage === undefined;
          if (showCannotUseBenefitPage || waitingForResponse) return Promise.resolve(false);
          return Promise.resolve(true);
        });
      
    if (collaboration && address) {
      fetchSummary();
    }
  }, [promotionId, collaboration, address, subTotalPrice, deliveryMethod]);

  const checkCanUseAddOnBenefit = () => {
    return new  Promise((resolve, reject) => {
      const addOnBenefit = getAddOnBenefit(window.location.search);
      const params = getUrlParams(window.location.search);
      const passVerify = params.passVerify;

      if (!addOnBenefit || passVerify) resolve(true);

      dispatch(checkMembershipCardBenefit(card, true, addOnBenefit))

      const result = canUseBenefit !== null ? canUseBenefit : undefined;
      resolve(result);
    });
  }

  const showCannotUseBenefitPage = () => {
    return new Promise((resolve, reject) => {
      if (canUseBenefit) resolve(false);

      dispatch(setCanUseBenefit(null));
      dispatch(setAddOnBenefit(null));

      const params = getUrlParams(window.location.search);
      
      const passVerify = params.passVerify;
      if (passVerify) return resolve(false);

      const { add_on_benefit, ...excludeAddOnBenefitParams } = params;
      const queryString = new URLSearchParams(excludeAddOnBenefitParams).toString();
      const pathname = window.location.pathname;
      const pathWithParams = `${pathname}&${queryString}`
      navigate(`/membership/cannotUseBenefit?flow=drugStore&nextPage=${pathWithParams}`);

      reject()
    });
  }

  const fetchSummary = async () => {
    try {
      const options = { chatroom : chatroom, add_on_benefit: addOnBenefit, card: card };
      const res = await dispatch(postOrderSummary(options, user.is_member));
      setSummary(res);
    } catch (e) {
      setSummary();
      if (e.message) {
        if (e.message.detail && e.message.detail.includes('address')){
          alert('คุณอยู่นอกพื้นที่ให้บริการ');
        }
      }
    }
  }

  const submit = async () => {
    try {
      const options = { chatroom : chatroom, add_on_benefit: addOnBenefit, card: card };
      const res = await dispatch(postOrder(options, user.is_member));
      const url = res.payment_redirect_uri;
      if (url) {
        if (url.includes('/scanPromptpay?')) {
          navigate(`/scanPromptpay?${url.split('/scanPromptpay?')[1]}`);
        } else if (url.includes('/mbanking?')) {
          navigate(`/mbanking?${url.split('/mbanking?')[1]}`);
        } else {
          navigate(`/result/cardpayment?uri=${url}`)
        }
      } else {
        throw 'error';
      }
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  const handleSelect = id => {
    dispatch(addMenu(id));
  };

  const handleDeselect = id => {
    dispatch(removeMenu(id));
  };
  
  const disabledButton = loading || !summary || !payment;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ซื้อยา - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <h2>สรุปการสั่งซื้อ</h2>
        <CheckoutItems order={currentSelected} onSelect={handleSelect} onDeselect={handleDeselect}/>
        <CheckoutForm delivery />
        <Payment title/>
        <Promotion title filter="shop" />
        <CheckoutSummary title deliveryTitle="ค่าส่ง" summary={summary}/>
        <Box mt={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabledButton}
            onClick={submit}
          >
            ชำระเงิน {summary && displayCurrency(summary.grand_total_price)}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
